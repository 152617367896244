@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

#body{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
}

#fake-nav-container{
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 5%;
}

#home-link-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    text-decoration: none;
}

#icon-img{
    width: 10%;
    height: 10%;
    border-radius: 50px;
}

#home-link-container-p{
    font-size: 28px;
    margin-left: 5px;
    color: #171616;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif
}

#error-page{
    font-family: 'Montserrat', sans-serif;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 50%;
    max-height: 25%;
    justify-content: center;
    align-content: center;
    align-items: center;
}

#error-page h1{
    font-size: 75px;
}

#error-page p{
    color: #171616;
    font-size: 24px;
}

#header-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#errorDino-img{
    width: 10%;
    aspect-ratio: 1;
    border-radius: 100px;
    margin-left: 2%;
}