@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');


h1{
  font-family: 'Montserrat', sans-serif;
  font-size: 2vw;
  font-weight: 600;
  color: #000000;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/*Pop Up Styles*/

.popup-parent{
  position: absolute; 
  height: 100vh;
  width: 100%;
  z-index: 10;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.popup-parent-mobile{
  position: absolute; 
  height: 100vh;
  width: 100%;
  z-index: 10;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.popup-blur{
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 11;
  align-self: center;
  background-color: #404040;
  opacity: 0.7;
}

.popup-main{
  display: flex; 
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  position: absolute;
  height: 50vh;
  width: 50vw;
  background-color: #FFFFFF;
  border-radius: 27px;
  opacity: 1;
  z-index: 12;
}

.popup-main-mobile{ 
  display: flex; 
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  position: absolute;
  height: 60vh;
  width: 85vw;
  background-color: #FFFFFF;
  border-radius: 27px;
  opacity: 1;
  z-index: 12;
  margin-bottom: 100px;
}

.popup-text-container{
  display: flex; 
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 50%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-inline: 25px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
}

.popup-text-container-mobile{
  display: flex; 
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  padding-inline: 25px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
}

.popup-body{
  font-family: 'Montserrat', sans-serif; 
  font-weight: 400;
  font-size: 1vw;
  color: #000000;
  text-align: center;
}

.popup-body-mobile{
  font-family: 'Montserrat', sans-serif; 
  font-weight: 400;
  font-size: 4vw;
  color: #000000;
  text-align: center;
}

#beta-button{
  background-color: #8b3dd9;
  color: #FFFFFF;
  font-size: 1vw;
  padding-inline: 25px;
  padding-block: 20px;
  border-radius: 35px;
  border: 3px solid #FFFFFF;
}

#beta-button-mobile{
  background-color: #8b3dd9;
  color: #FFFFFF;
  font-size: 4vw;
  padding-inline: 25px;
  padding-block: 20px;
  border-radius: 35px;
  border: 3px solid #FFFFFF;
}

#beta-button-bottom{
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
  color: #FFFFFF;
  font-size: 1vw;
  padding-inline: 25px;
  padding-block: 20px;
  border-radius: 35px;
  opacity: 0.4;
}

#beta-button-bottom-mobile{
  border: none;
  background-color: rgba(255, 255, 255, 0.25);
  color: #FFFFFF;
  font-size: 4vw;
  padding-inline: 25px;
  padding-block: 20px;
  border-radius: 35px;
  opacity: 0.4;
}

/*Temporary Beta Closed Window*/

.hidden-modal{
  transform: translateY(-75px);
  opacity: 1;
}

.show-modal{
  animation: showModal 1s forwards;
}

.hide-modal{
  opacity: 1;
  animation: hideModal 1s forwards;
}

@keyframes hideModal{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@keyframes showModal{
  0%{
    transform: translateY(-75px);
  }
  100%{
    transform: translateY(0px);
  }
}


.hidden-modal-mobile{
  transform: translateY(-250px);
  opacity: 1;
  box-sizing: border-box;
}

.show-modal-mobile{
  animation: showModalMobile 1s forwards;
}

.hide-modal-mobile{
  opacity: 1;
  animation: hideModalMobile 1s forwards;
}

@keyframes hideModalMobile{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}

@keyframes showModalMobile{
  0%{
    transform: translateY(-250px);
  }
  100%{
    transform: translateY(0px);
  }
}

.App-header {
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
}

.nav-bar {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-height: 8vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  float: top;
  top: 0;
  z-index: 5;
}

.nav-bar-mobile {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  max-height: 8vh;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: fixed;
  float: top;
  top: 0;
  z-index: 1;
  background-color: #FFFFFF;
}

#logo{
  width: 35%;
  height: auto;
  margin-bottom: -15px;
  padding-top: 15px;
}

#logo-mobile{
  width: 20%;
  height: auto;
}

.nav-bar a {
  position: relative;
  text-decoration: none;
  color: #8b3dd9;
}

.nav-link{
  padding-right: 25px;
  color: #000000;
  font-size: 1.25vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.nav-link-mobile{
  padding-right: 10px;
  color: #8b3dd9;
}

.nav-button{
  color: #000000;
  font-size: 1.25vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
  padding-inline: 25px;
  z-index: 1;
  position: relative;
}

.nav-button-mobile{
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.site-header-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: default;
  max-width: 5%;
}

.site-header-container-mobile{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: default;
  max-width: 30%;
}

.site-header{
  color: #242424;
  font-size: 1.5vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.site-header-mobile{
  color: #242424;
  font-size: 2.5vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding-left: 5px;
}

.coming-download{
  color: #8b3dd9;
  font-size: 1.75vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.mobile-nav-icon-group{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 15px;
}

.mobile-nav-icon-group p{
  color: #FFFFFF;
  font-size: 1.25vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.beta-test-button-mobile{
  background-color: #8b3dd9;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 5px;
  border-radius: 25px;
}

#coming-download-button{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 3.5vh;
  max-width: 14vw;
  border-radius: 35px;
  margin-right: 2vw;
  background-color: rgba(139, 61, 217, 0.25);
  cursor: default;
  opacity: 0.3;
}

.landing-container{
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  top: -8vh;
  z-index: 0;
  background: linear-gradient(210.4deg, rgba(139, 61, 217, 0.5) 0%, rgba(255, 255, 255, 0) 50.45%);
  width: 100%; 
  height: 100vh;
  padding-top: 5vh;
  margin-bottom: -5vh;
}

.landing-container-mobile{
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  top: -8vh;
  z-index: -1;
  background: linear-gradient(210.4deg, rgba(139, 61, 217, 0.5) 0%, rgba(255, 255, 255, 0) 50.45%);
  width: 100%; 
  height: 100vh;
  margin-bottom: -5vh;
}

.landing-image-right{
  position: absolute;
  z-index: -2;
  width: 14%;
  padding-left: 6.5%;
  padding-bottom: 15%;
  margin-bottom: 0;
}

.landing-image-left{
  position: absolute;
  z-index: -3;
  width: 19%;
  margin-bottom: 0;
  padding-right: 6.5%;
}

.landing-text{
  position: absolute;
  bottom: 12vh;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 3.75vh;
}

.landing-image-right-mobile{
  position: absolute;
  z-index: -2;
  width: 43%;
  padding-left: 25%;
  padding-bottom: 50%;
  margin-bottom: 0;
}

.landing-image-left-mobile{
  position: absolute;
  z-index: -3;
  width: 62%;
  margin-bottom: 0;
  padding-right: 6.5%;
}

.arrow-container{
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  height: 3vh;
  bottom: 9vh;
  padding-inline: 5px;
  box-sizing: border-box;
  z-index: 0;
}

/*Start Body*/

.infographic-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 17vh;
}

.infographic-container-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 20vh;
  margin-bottom: 25px;
}

.infographic-image{
  scale: 1.75;
  align-self: center;
}

.infographic-image-mobile{
  width: 100%;
  transform: scale(1.25);
  align-self: center;
}

.infographic-text{
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2.5vh;
  width: 28%;
  text-align: center;
  padding-top: 10px;
}

.infographic-text-mobile{
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2.5vh;
  width: 90%;
  text-align: center;
  padding-top: 10px;
}

#info-link{
  text-decoration: underline;
  cursor: pointer;
}

.parent-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #FFFFFF;
  height: 85vh;
}

.parent-container-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #FFFFFF;
}


/*////////////////////Desktop Splits///////////////////////*/

#first-container{
  width: 100%;
  height: 75vh;
  background: linear-gradient(15.03deg, rgba(139, 61, 217, 0.5) 0%, rgba(255, 255, 255, 0) 55.47%);
}

.split-container{
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  width: 75%;
  height: 30vh;
  padding-inline: 5vw;
  padding-bottom: 2vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.split-container-child{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content:center;
  align-items: center;
  width: 50%;
  padding-top: 10%;
}

.split-container-child-content{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content:center;
  align-items: center;
  width: 65%;
  height: 80vh;
}

.mobile-container-child-content{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content:center;
  align-items: center;
  width: 65%;
  height: 100vh;
}

.split-container-child p, .split-container-child-content p{
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9vw;
  font-weight: 500;
  text-align: center;
  width: 90%;
  text-align: justify;
}

.split-container-child-content h1{
  color: #000000;
}

#second-container{
  background: linear-gradient(163.03deg, rgba(139, 61, 217, 0.5) 0%, rgba(255, 255, 255, 0) 55.47%);
  z-index: -1;
}

#second-container-last{
  z-index: -1;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

#second-container h1, p{
  color: #FFFFFF;
}

#second-container-last h1, p{
  color: #FFFFFF;
}

.single-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  padding-inline: 5vw;
  padding-block: 2vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding-top: 8vh;
  
}

.single-container p{
  font-family: 'Montserrat', sans-serif;
  font-size: 1vw;
  font-weight: 400;
  text-align: center;
  width: 40%;
}

.get-excited-a{
  position: relative;
  z-index: 15;
}

.bullet-container{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 10%;
}

.bullet-container-mobile{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 25%;
  margin-block: 5px;
}

.bulletImage{
  width: 15%;
  scale: 0.75;
  margin-right: 10px;
  align-self: center;
}

.bulletImageMobile{
  width: 15%;
  scale: 0.75;
  margin-right: 25px;
  align-self: center;
}

.bullet-text{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #000000;
  font-size: 2vh;
}

.grid-parent{
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 50% 50%;
  width: 65%;
  padding-top: 5vh;
}

.grid-parent-mobile{
  display: grid;
  grid-template-columns: 100%;
  width: 95%;
  padding-top: 5vh;
}

.grid-child{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.grid-child-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.grid-child-mobile p{
  min-width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  text-align: left;
  height: 75%;
}

.grid-child p{
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.6vh;
  text-align: left;
  height: 75%;
}

.grid-child h2{
  font-weight: 500;
  font-size: 2.5vh;
}

.grid-image{
  width: 30%;
  padding-right: 15px;
}

.grid-image-mobile{
  width: 35%;
}

.inner-grid-child{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: 25%;
  padding-block: 15px;
  padding-bottom: 25px;
}

.inner-grid-child-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.inner-grid-child-mobile h2 {
  width: 100%;
  text-align: center;
  font-size: 5.75vw;
}

.hidden-container{
  opacity: 0;
  transform: translateY(15px);
}

.fadeIn-anim{
  opacity: 1;
  transform: translateY(15px);
  animation: show 1s forwards;
}

.fadeIn-anim-down{
  opacity: 1;
  transform: translateY(-15px);
  animation: showdown 1s forwards;
}

@keyframes show{
  0%{
    opacity: 0;
    transform: translateY(15px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes showdown{
  0%{
    opacity: 0;
    transform: translateY(-15px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}

.quiz-container{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 15px 50px #D9D9D9;
  width: 50%;
  max-height: 50%;
  border-radius: 25px;
}

.quiz-container-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 15px 50px #D9D9D9;
  width: 90%;
  min-height: 65%;
  border-radius: 25px;
}

.quiz-container-mobile h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 3vh;
  width: 95%;
  text-align: center;
}

.quiz-container-mobile h3{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2.5vh;
  width: 100%;
  text-align: center;
}

.quiz-container-mobile button{
  border: none;
  background-color: #8b3dd9;
  color: #FFFFFF;
  padding-inline: 25px;
  padding-block: 10px;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.5vh;
  border-radius: 75px;
  cursor: pointer;
  border: solid 3px #8b3dd9;
}

.quiz-container h2{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 3vh;
  width: 65%;
  text-align: center;
}

.quiz-container h3{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 2.5vh;
  width: 100%;
  text-align: center;
}

.quiz-container button{
  border: none;
  background-color: #8b3dd9;
  color: #FFFFFF;
  padding-inline: 25px;
  padding-block: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.5vh;
  border-radius: 75px;
  cursor: pointer;
  border: solid 3px #8b3dd9;
}

.slide-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
}

#instagram-api-container{
  background-color: #FFFFFF;
  box-shadow: 0px 15px 75px #D9D9D9;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-bottom: 15px;
}

.instagram-img-container{
  max-width: 30%;
  margin: 15px;
  border: 2px solid #F5F5F5;
  position: relative;
}

.instagram-img-container-mobile{
  max-width: 75%;
  margin: 15px;
  border: 2px solid #F5F5F5;
  position: relative;
}

#instagram-api-container-mobile{
  max-width: 95%;
  border: 2px solid #F5F5F5;
  position: relative;
  margin-bottom: 75px;
}

.instagram-img{
  width: 100%;
  aspect-ratio: 1;
}

.carousel-svg{
  position: absolute;
  width: 10%;
  right: 1%;
  top: 1%;
}

.instagram-feed-container{
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.instagram-feed-container-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#instagram-api-container h1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 3vh;
  padding-top: 15px;
}

#instagram-api-container-mobile h1{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 2.5vh;
  padding-top: 15px;
}

.instagram-title-container{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-inline: 50px;
}

.instagram-title-container-mobile{
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding-inline: 50px;
}

.instagram-title-container-mobile h1{
  font-size: 6vh;
}

/*/////////////////////////////Mobile Splits/////////////////////////////*/

.mobile-container{
  display: flex;
  flex-direction: column;
  flex: 2;
  flex-wrap: wrap;
  width: 100%;
  height: 75vh;
  padding-inline: 5vw;
  margin-top: 35vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.mobile-container-child{
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content:center;
  align-items: center;
  width: 100%;
}

.mobile-container-child p{
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5vw;
  font-weight: 500;
  text-align: justify;
  width: 100%;
  padding-inline: 5vw;
}

#first-container p{
  color: #242424;
}

#second-container-mobile{
  background: linear-gradient(322deg, #3d73d6, #8b3dd9);
  z-index: 0;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

#second-container-mobile-last{
  background: linear-gradient(270deg, #3d73d6, #8b3dd9);
  z-index: 0;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

#second-container-mobile h1, p{
  color: #FFFFFF;
}

#second-container-mobile-last h1, p{
  color: #FFFFFF;
}

.single-container-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  padding-block: 2vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
}

.single-container-mobile p{
  font-family: 'Montserrat', sans-serif;
  font-size: 5vw;
  font-weight: 400;
  text-align: center;
  width: 80%;
}

.get-excited-a{
  position: relative;
  z-index: 15;
}

#section-five-background{
  background: linear-gradient(345.03deg, rgba(139, 61, 217, 0.45) 0%, rgba(255, 255, 255, 0) 59.47%);
}

#section-six-background{
  background: linear-gradient(195deg, rgba(139, 61, 217, 0.45) 0%, rgba(255, 255, 255, 0) 59.47%);
}

#help-form-header{
  font-family: 'Montserrat', sans-serif;
  font-size: 2vw;
  font-weight: 600;
}

#help-form{
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 45%;
  max-height: 50%;
  position: relative;
}

#help-form-mobile{
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 85%;
  position: relative;
}

.name-email-container{
  display: flex;
  flex: 1;
  flex-direction: row;
  max-height: 6%;
  width: 100%;
  justify-content: space-between;
}

.name-email-container-mobile{
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

#name-label, #email-label, #message-label{
  height: 5%;
  font-family: 'Montserrat', sans-serif;
  font-size: 1vw;
  font-weight: 400;
}

#name-label-mobile, #email-label-mobile, #message-label-mobile{
  font-family: 'Montserrat', sans-serif;
  font-size: 4vw;
  font-weight: 400;
  margin-block: 15px;
}

#email-label{
  padding-left: 25px;
}

#from_name, #from_email{
  height: 100%;
  width: 21%;
  margin-left: 5px;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  padding-inline: 5px;
  font-size: 0.65vw;
}

#from_name-mobile, #from_email-mobile{
  width: 80%;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  padding-inline: 5px;
  font-size: 3.5vw;
  padding-block: 5px;
}

#message{
  border: 1px solid #D9D9D9;
  resize: none;
  border-radius: 10px;
  height: 55%;
  margin-top: 15px;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.65vw;
}

#message-mobile{
  border: 1px solid #D9D9D9;
  resize: none;
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 3.5vw;
}

#submit-button{
  width: 15%;
  position: absolute;
  bottom: 10%;
  right: 0%;
  border: none;
  background-color: #8b3dd9;
  padding-block: 10px;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.7vw;
  border-radius: 30px;
}

#submit-button-mobile{
  width: 35%;
  right: 0%;
  border: none;
  background-color: #8b3dd9;
  padding-block: 10px;
  color: #FFFFFF;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 4vw;
  border-radius: 30px;
  margin-top: 15px;
  margin-left: 65%;
}

.footer{
  padding: 0.5vw;
  color: #000000;
  text-align: center;
  background-color: #FFFFFF;
}

.footer-mobile{
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0.5vw;
  color: #000000;
  text-align: center;
  margin-bottom: 25px;
  justify-content: space-between;
  align-items: 'center';
  align-content: 'center';
}

.footer p, .footer-mobile p{
  color: #000000;
}

.footer a, .footer-mobile a{
  text-decoration: underline;
}