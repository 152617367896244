@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

#body{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
}

#fake-nav-container{
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 5%;
}

#home-link-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 16px;
    text-decoration: none;
}

#icon-img{
    width: 10%;
    height: 10%;
    border-radius: 50px;
}

#home-link-container-p{
    font-size: 28px;
    margin-left: 5px;
    color: #171616;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif
}

#form-container{
    font-family: 'Montserrat', sans-serif;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 50%;
    max-height: 25%;
    justify-content: center;
    align-content: center;
    align-items: center;
}

#form-container form{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#form-container h1{
    font-size: 45px;
}

#errorCodeText{
    color: red;
    font-size: 14px;
    margin-bottom: -15px;
}

#form-header{
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#copDino-img{
    width: 25%;
    aspect-ratio: 1;
    border-radius: 50%;
}

#confirmPassLabel{
    margin-inline: 15px;
    font-size: 18px;
}

#newPassLabel{
    margin-inline-end: 15px;
    font-size: 18px;
}

#newPass, #confirmPass{
    padding-block: 5px;
    padding-inline: 5px;
    border: 1px solid #C6C6C6;
}

#passSubmitButton{
    all: unset;
    position: relative;
    background-color: #8b3dd9;
    padding-inline: 10px;
    margin-top: 25px;
    align-self: center;
    border: solid 3px #FFFFFF;
    border-radius: 50px;
    cursor: pointer;
}

#passSubmitButton:hover{
    background-color: #FFFFFF;
    border: solid 3px #8b3dd9;
    transition: 500ms;
}

#passSubmitButton:hover p{
    color: #8b3dd9;
}

#passSubmitButton p{
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
}

#confirmation-modal{
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 25%;
    height: 25%;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

#confirmation-modal-mobile{
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 85%;
    height: 30%;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

#confirmation-modal p{
    color: #171616;
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    text-align: center;
}

#confirmation-modal-mobile p{
    color: #171616;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    text-align: center;
}

#modal-backdrop{
    background-color: #171616;
    width: 100vw;
    height: 100vh;
    position: absolute;
    opacity: 0.75;
}

#modal-img{
    width: 30%;
    aspect-ratio: 1;
    margin-bottom: -40px;
}